/* eslint-disable @typescript-eslint/no-explicit-any */
import { COMPANY_FLOW_TYPE, DRAWER_FLOW_TYPE, PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";
import { useAppContext } from "context/AppContext";
import useGetData from "hooks/api/useGetData";
import useGetSelectedPaymentMethodData from "hooks/utils/useGetSelectedPaymentMethodData";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PropagateLoader } from "react-spinners";
import { renderLogo } from "utils/renderLogo";
import Dialog from "./base/Dialog";
import Instruction from "./base/Instruction";
import TableOrder from "./base/TableOrder";
import styles from "./styles.module.scss";
import PrlOrder from "./ui/YourOrder/PrlOrder";
import ProductOrder from "./ui/YourOrder/ProductOrder";

interface ModalDialogProps {
  flowType: string;
  showModal: boolean;
  closeModal: () => void;
  onConfirmation: () => void;
  isLoadingCreateQuote: boolean;
  initiatePayment: boolean;
  content?: any;
  footerRows?: any;
  isLoadingQuote: boolean;
}

interface FlowTypeConfig {
  message: string;
  content: (
    initiatePayment: boolean,
    isLoadingQuote: boolean,
    content?: any,
    footerRows?: any
  ) => JSX.Element;
}

const FlowTypeModal: React.FC<ModalDialogProps> = ({
  flowType,
  showModal,
  closeModal,
  onConfirmation,
  isLoadingCreateQuote,
  initiatePayment,
  content,
  footerRows,
  isLoadingQuote
}) => {
  const { t, i18n } = useTranslation();
  const appContext = useAppContext();
  const { data: paymentMethodData } = useGetSelectedPaymentMethodData();
  const { productData, prlData, companyData, drawerData, themes } = useGetData();
  const imgUrl =
    flowType === DRAWER_FLOW_TYPE
      ? drawerData?.merchant?.logo
      : flowType === COMPANY_FLOW_TYPE
      ? companyData?.logo
      : (productData || prlData)?.merchant?.logo;

  const CodeValidation = () => (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <div className={styles.paymentLogo}>
        <img src={imgUrl} alt="Logo" style={{ width: "100%" }} />
      </div>

      <PropagateLoader data-testid="loading-screen-loader" size={10} color={themes.primaryColor} />
      <div className={styles.waitText} data-testid="loading-screen-title">
        {t("secondScreen.pleaseWait")}
        {"..."}
      </div>
      <Instruction
        imgUrl={renderLogo(appContext?.paymentMethod)}
        htmlContent={paymentMethodData?.instructions?.find((l) => l.lang === i18n.language)?.text}
      />
    </div>
  );

  const flowTypeComponents: Record<string, FlowTypeConfig> = {
    [PRODUCT_FLOW_TYPE]: {
      message: "secondScreen.confirmPayment",
      content: (initiatePayment, isLoadingQuote) =>
        initiatePayment ? <CodeValidation /> : <ProductOrder isLoading={isLoadingQuote} showHeader={false} />
    },
    [DRAWER_FLOW_TYPE]: {
      message: "secondScreen.confirmPayment",
      content: (initiatePayment, isLoadingQuote, content, footerRows) =>
        initiatePayment ? (
          <CodeValidation />
        ) : (
          <TableOrder content={content} footerRows={footerRows} isLoading={!isLoadingQuote} />
        )
    },
    [PRL_FLOW_TYPE]: {
      message: "secondScreen.confirmPayment",
      content: (initiatePayment, isLoadingQuote) =>
        initiatePayment ? (
          <CodeValidation />
        ) : (
          <PrlOrder isLoading={isLoadingQuote} showHeader={false} summary={true} />
        )
    }
  };

  const flowTypeConfig = flowTypeComponents[flowType];

  if (!flowTypeConfig || !showModal) {
    return null;
  }

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Dialog
        message={t(flowTypeConfig.message)}
        onConfirm={onConfirmation}
        onCancel={closeModal}
        isLoading={isLoadingCreateQuote}
        buttonDisplay={initiatePayment}
      >
        {flowTypeConfig?.content(initiatePayment, isLoadingQuote, content, footerRows)}
        <div className={"mt-3"}></div>
      </Dialog>
    </Modal>
  );
};

export default FlowTypeModal;
